import { Component, OnInit, OnDestroy, ElementRef, Renderer2, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { NavmenuComponent } from '../navmenu/navmenu.component';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  standalone: true,
  imports: [NavmenuComponent, CommonModule],
})
export class NavigationComponent implements OnInit, OnDestroy {
  menu: { id: number; title: string; link: string }[];
  menuOpen: boolean;
  private clickListener: () => void;

  constructor(private location: Location, private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.menuOpen = false;
    this.getMenu();
    this.clickListener = this.renderer.listen('document', 'click', (event: Event) => {
      if (!this.elRef.nativeElement.contains(event.target)) {
       // this.toggleMenu(true);
      }
    });
  }

  

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }

  toggleMenu(status: boolean) {
    this.menuOpen = status;
  }

  getMenu() {
    this.menu = [
      { id: 1, title: 'Home', link: '/home' },
      { id: 2, title: 'About Us', link: '/about' },
      { id: 3, title: 'Our Products', link: null },
      { id: 4, title: 'Certificates', link: '/certificate' },
      { id: 5, title: 'E-Brochure', link: null },
      { id: 6, title: 'Contact Us', link: '/contact' },
    ];
  }

}
