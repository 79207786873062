import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true,
    imports: [
        RouterLink,
        AsyncPipe,
    ],
})
export class FooterComponent implements OnInit {
    link31='/product/31';
    link32='/product/32';
    link34='/product/34';
    link35='/product/35';
	constructor() {}

	ngOnInit() {
		this.getPageData('pages', 6);
	}

	getPageData(database: string, id?: number): void {
		
	}
}
