// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
  margin-top: 0px;
}

span{
  font-size: xx-large;
  font-weight: bold;
}

app-navmenu{
  
  font-size: medium;
  font-weight: bold;
}

#nav-main{
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/navigation/navigation.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;;EAEE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["#header {\n  margin-top: 0px;\n}\n\nspan{\n  font-size: xx-large;\n  font-weight: bold;\n}\n\napp-navmenu{\n  \n  font-size: medium;\n  font-weight: bold;\n}\n\n#nav-main{\n  margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
