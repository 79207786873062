import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, HostListener  } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';

interface MenuItem {
  id: number;
  link: string;
  title: string;
  isOpen?: boolean;
}

@Component({
  selector: 'app-navmenu',
  templateUrl: './navmenu.component.html',
  styleUrls: ['./navmenu.component.css'],
  standalone: true,
  imports: [RouterLinkActive, RouterLink, CommonModule],
})
export class NavmenuComponent implements OnInit {
	submenu: { id: number; title: string; link: string }[];
  submenu1: { id: number; title: string; link: string }[];
  @Input() menu: MenuItem[];
  @Input() menuOpen: boolean;
  @Output() menuStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  submenuOpen:boolean = false;
  submenuOpen1:boolean = false;
  private isSubMenuClicked = false;
  private isSubMenu1Clicked = false;

  constructor() {}

  ngOnInit() {
	this.getMenu();
  }

  toggleSubMenu(toggle:any){
    
    this.submenuOpen1 = false;
    this.submenuOpen = !this.submenuOpen;
    console.log(this.submenuOpen);
    this.isSubMenuClicked = true;
	if(toggle == 'toggleMain'){

	}else{
		//this.menuStatus.emit(!this.menuOpen);
	}
  }

  toggleSubMenu1(toggle:any){
    this.submenuOpen = false;
    this.submenuOpen1 = !this.submenuOpen1;
    this.isSubMenu1Clicked = true;
	if(toggle == 'toggleMain'){

	}else{
		//this.menuStatus.emit(!this.menuOpen);
	}
  }

  toggleMenu(): void {
	this.menuStatus.emit(!this.menuOpen);
	
  }

  trackByFn(index: number, item: MenuItem): number {
    return item.id;
  }

  getMenu() {
	//this.submenuOpen = true;
	this.submenu = [
		{ id: 31, title: 'Tarpaulins', link: '/product/31' },
		{ id: 32, title: 'Stretch Films', link: '/product/32' },
		{ id: 34, title: 'Agriculture Shade Nets', link: '/product/34' },
		{ id: 35, title: 'Flexible Packaging Materials', link: '/product/35' },
	  ];

    this.submenu1 = [
      { id: 36, title: 'Tarpaulin', link: '/e-brochure' },
      { id: 37, title: 'Agriculture Shade Net', link: '/agn-e-brochure' },
     
      ];
	  
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    if (this.isSubMenuClicked) {
      // Reset the flag after handling the click
      this.isSubMenuClicked = false;
      return;
    }

    if (this.isSubMenu1Clicked) {
      // Reset the flag after handling the click
      this.isSubMenu1Clicked = false;
      return;
    }

    // Close submenu if the click is outside the submenu and "Our Products" menu item
    if (!target.closest('.sub-menu') && !target.closest('a[routerLink="/products"]')) {
      this.submenuOpen = false;
    }

    if (!target.closest('.sub-menu1') && !target.closest('a[routerLink="/products"]')) {
      this.submenuOpen1 = false;
    }
  }

}
