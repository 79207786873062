import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'about.html', redirectTo: 'home', pathMatch: 'full' },
	{
		path: 'home',
		loadComponent: () =>
			import('./home/home-page/home-page.component').then(
				mod => mod.HomePageComponent
			),
	},
	{
		path: 'e-brochure',
		loadComponent: () =>
			import('./e-brochure/e-brochure.component').then(
				mod => mod.EbrochureComponent
			),
	},
	{
		path: 'agn-e-brochure',
		loadComponent: () =>
			import('./agn-e-brocure/agn-brochure.component').then(
				mod => mod.AgnbrochureComponent
			),
	},
	{
		path: 'about',
		loadComponent: () =>
			import('./about/about-page/about-page.component').then(
				mod => mod.AboutPageComponent
			),
	},
	{
		path: 'contact',
		loadComponent: () =>
			import('./contact/contact.component').then(
				mod => mod.ContactComponent
			),
	},
	{
		path: 'certificate',
		loadComponent: () =>
			import('./certificate/certificate.component').then(
				mod => mod.CertificateComponent
			),
	},
	{
		path: 'product/:id',
		loadComponent: () =>
			import('./product/product.component').then(
				mod => mod.ProductComponent
			),
	},
	{
		path: '404',
		loadChildren: () =>
			import('./notfound/notfound-page/notfound-page.component').then(
				mod => mod.NotfoundPageComponent
			),
	},
	{ path: '**', redirectTo: '/404' },
];

@NgModule({
	imports: [CommonModule, RouterModule.forRoot(routes)],
	exports: [RouterModule],
	declarations: [],
})
export class AppRoutingModule {}
